import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../../context-api/KycContext";
import "../../../../styles/modals.scss";
import "../../../../styles/bills.scss";
import { variableManager } from "../../../../context-api/VariableContex";

import dstv from "../../../../icons/dstv.svg";
// import dstv from "../../../../icons/dstv.png";
// import gotv from "../../../../icons/gotv.png";
import gotv from "../../../../icons/gotv.svg";
import startimes from "../../../../icons/startimes.svg";
// import startimes from "../../../../icons/startimes.png";
import startimeOn from "../../../../icons/startimes_on.svg";
// import startimeOn from "../../../../icons/startimeOn.webp";
import showmax from "../../../../icons/showmax.svg";
// import showmax from "../../../../icons/showmax.png";
import enter from "../../../../icons/enter.svg";
import { RenameFile } from "../../../../utils/RenameFiles";
import { AiFillCheckCircle } from "react-icons/ai";
import { HandleSelected } from "../../../../utils/HandleSelected";
import { getBundlesFor } from "../../../../features/bills_payment/DataBundles";
import { formatAmount } from "../../../../utils/formatAmount";
import axiosInstance from "../../../../utils/axios_instance";
import { BsArrowRight } from "react-icons/bs";
export default function CableDetails(props) {
  const {
    toverify,
    handleBillStatusChange,
    selectedBillProvider,
    setSelectedBillProvider,
    billPayload,
    setBillPayload,
  } = useContext(KycManager);
  const { user, msg, setMsg } = useContext(variableManager);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [bundles, setBundles] = useState({ all: [], selected: { amount: "" } });
  const [error, setError] = useState(false);
  useEffect(() => {
    handleGetBudles();
  }, [JSON.stringify(selectedBillProvider.cable)]);

  function handleChange(e) {
    const value =
      e.target.type === "file"
        ? RenameFile(
            e.target.files[0],
            `${user.firstName.toLowerCase()}${user.surname.toLowerCase()}`
          )
        : e.target.value;
    setBillPayload({
      ...billPayload,
      cableTv: {
        ...billPayload.cableTv,
        [e.target.name]: value,
        validCustomer: "",
      },
    });
  }

  async function handleGetBudles(billerCode) {
    setBundles({ ...bundles, all: [], selected: {} });
    if (selectedBillProvider.cable.dstv) {
      billerCode = "BIL121";
    } else if (selectedBillProvider.cable.gotv) {
      billerCode = "BIL122";
    } else if (selectedBillProvider.cable.startime) {
      billerCode = "BIL123";
    } else if (selectedBillProvider.cable.smile) {
      billerCode = "BIL124";
    }

    // else if (selectedBillProvider.cable.startimeOn) {
    //   billerCode = "BIL111";
    // }else if (selectedBillProvider.cable.showmax) {
    //   billerCode = "BIL111";
    // }

    const { data, error } = await getBundlesFor(billerCode);
    if (data) {
      setBundles({ ...bundles, all: data });
    } else {
      console.log(error);
    }
  }

  async function validateCustomer(customer) {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .post("/business/bill/validate", customer)
        .then((resp) => {
          const data = resp.data.data;
          if (data) {
            setBillPayload({
              ...billPayload,
              cableTv: {
                ...billPayload.cableTv,
                validCustomer: data.name,
              },
            });
            setMsg({ ...msg, spinner: false });
            return true;
          }
        });
    } catch (error) {
      setError(
        "unable to verify details at the moment, verify details and try again"
      );
      setMsg({ ...msg, spinner: false });
      return false;
    }
  }
  // console.log(billPayload)

  function handleReset() {
    setBundles({ ...bundles, selected: "" });
    setBillPayload((prevPayload) => ({
      ...prevPayload,
      airtimeData: {
        ...prevPayload.airtimeData,
        customer: "",
        amount: "",
        type: "Airtime",
        pin: "",
        code: "",
        itemCode: "",
      },
      cableTv: {
        ...prevPayload.cableTv,
        customer: "",
        amount: "",
        type: "",
        pin: "",
        code: "",
        itemCode: "",
        validCustomer: "",
      },
    }));
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Cable TV</h2>

        {billPayload.cableTv.type && (
          <div className="transaction-fee">+ ₦25 Transaction Fee</div>
        )}

        <div className="request-pos-container cableTv-container">
          <div className="network-providers-section">
            <p className="select-network">Select Network Provider</p>

            <div className="network-providers">
              <div
                className={
                  selectedBillProvider.cable.dstv
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  HandleSelected(selectedBillProvider, "cable", "dstv");
                }}
              >
                <img src={dstv} draggable="false" alt="dstv" />
              </div>
              <div
                className={
                  selectedBillProvider.cable.gotv
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  // handleSelection("cable", "gotv");
                  HandleSelected(selectedBillProvider, "cable", "gotv");
                  handleReset();
                }}
              >
                <img src={gotv} draggable="false" alt="9mobile" />
              </div>
              <div
                className={
                  selectedBillProvider.cable.startime
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  // handleSelection("cable", "startime");
                  HandleSelected(selectedBillProvider, "cable", "startime");
                  handleReset();
                }}
              >
                <img src={startimes} draggable="false" alt="startimes" />
              </div>
              {/* <div
                className={
                  selectedBillProvider.cable.startimeOn
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  // handleSelection("cable", "startimeOn");
                  HandleSelected(selectedBillProvider, "cable", "startimeOn");
                  handleReset();
                }}
              >
                <img src={startimeOn} draggable="false" alt="startimes On" />
              </div> */}
              {/* <div
                className={
                  selectedBillProvider.cable.showmax
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  // handleSelection("cable", "showmax");
                  HandleSelected(selectedBillProvider, "cable", "showmax");
                  handleReset();
                }}
              >
                <img src={showmax} draggable="false" alt="showmax" />
              </div> */}
            </div>
          </div>

          {/* SELECT OPTION */}
          <div className="input-area">
            <div className="label">
              <label htmlFor="option">Select Plan</label>
            </div>
            <div className="input">
              <select
                name=""
                id="option"
                onChange={(e) => {
                  setError(false);
                  // handleChange(e);
                  setBillPayload({
                    ...billPayload,
                    cableTv: {
                      ...billPayload.cableTv,
                      code: "",
                      itemCode: "",
                      amount: "",
                      type: "",
                      validCustomer: "",
                      customer: "",
                    },
                  });
                  const selectedBundle = bundles.all.find(
                    (bundle) => bundle.name === e.target.value
                  );
                  setBundles({ ...bundles, selected: selectedBundle });
                  if (selectedBundle && selectedBundle.amount) {
                    setBillPayload({
                      ...billPayload,
                      cableTv: {
                        ...billPayload.cableTv,
                        code: selectedBundle.biller_code,
                        itemCode: selectedBundle.item_code,
                        amount: selectedBundle.amount,
                        type: selectedBundle.biller_name,
                      },
                    });
                  }
                }}
              >
                <option value="">Select Option</option>
                {bundles.all.length ? (
                  bundles.all.map((bundle, i) => {
                    return (
                      <option key={i} value={bundle.name}>
                        {bundle.name}
                      </option>
                    );
                  })
                ) : (
                  <option value="">loading...</option>
                )}
              </select>
            </div>
            {error && !billPayload.cableTv.type ? (
              <div className="mo-danger">Required field</div>
            ) : null}
          </div>
          {/* SMART CARD NUMBER */}
          <div className="input-area mb-1">
            <div className="label">
              <label htmlFor="smartCardNumber">
                {selectedBillProvider.cable.startimeOn
                  ? "Phone Number"
                  : "Smart Card Number"}
              </label>
            </div>

            <div className="inpu d-flex ">
              <input
                type="text"
                name="customer"
                id="smartCardNumber"
                placeholder={
                  selectedBillProvider.cable.startimeOn
                    ? "08154799404"
                    : "4573459875"
                }
                // disabled={billPayload.cableTv.validCustomer}
                value={billPayload.cableTv.customer}
                onChange={(e) => {
                  setError(false);
                  handleChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      billPayload.cableTv.customer &&
                      billPayload.cableTv.type
                    ) {
                      validateCustomer(billPayload.cableTv);
                    } else {
                      setError(true);
                    }
                  }
                }}
              />
              <div
                className="enter"
                onClick={() => {
                  if (
                    billPayload.cableTv.customer &&
                    billPayload.cableTv.type
                  ) {
                    validateCustomer(billPayload.cableTv);
                  } else {
                    setError(true);
                  }
                }}
              >
                {msg.spinner ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  <BsArrowRight />
                )}
              </div>
            </div>
            {error && billPayload.cableTv.customer === "" ? (
              <div className="mo-danger">Required field</div>
            ) : null}
          </div>

          {/* CHOOSE BENEFICIARY */}
          <div
            className="choose-beneficiary"
            onClick={() =>
              handleBillStatusChange("global", "chooseBeneficiary")
            }
          >
            Choose Beneficiary
          </div>

          {billPayload.cableTv.validCustomer && (
            <p>
              {" "}
              <AiFillCheckCircle className="mo-success" />
              {billPayload.cableTv.validCustomer}
            </p>
          )}

          {bundles.selected.amount && (
            <div className="input-area">
              <div className="label">
                <label htmlFor="quantity">Amount</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="quantity"
                  className="cableTv-amount"
                  name="amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={
                    formatAmount(`${bundles.selected.amount}`) || "00"
                  }
                  disabled
                />
              </div>
            </div>
          )}

          <div className="checkbox-area">
            <label htmlFor="saveBeneficiary">
              <input
                type="checkbox"
                name="saveBeneficiary"
                id="saveBeneficiary"
                onChange={(e) => {
                  handleChange(e);
                  setSaveBeneficiary(!saveBeneficiary);
                }}
              />{" "}
              Save Beneficiary
            </label>
          </div>
        </div>

        {saveBeneficiary && (
          <div className="input-area">
            <div className="label">
              <label htmlFor="beneficiary_name">Beneficiary Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="beneficiary_name"
                name="beneficiaryName"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="my startimes number"
              />
            </div>
          </div>
        )}

        {error && <div className=" error">{error}</div>}

        {billPayload.cableTv.validCustomer && (
          <div className="buttons">
            <button
              className={
                billPayload.cableTv.validCustomer
                  ? "btn-active"
                  : "btn-inactive"
              }
              onClick={() => {
                // handleFieldValidation();
                handleBillStatusChange("cableTv", "paymentMethod");
                // validateCustomer(billPayload.cableTv);
              }}
              disabled={
                !billPayload.cableTv.validCustomer || msg.spinner
                  ? "{true}"
                  : ""
              }
            >
              {msg.spinner ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Pay"
              )}
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
